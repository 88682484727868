import React from "react"
import { Link } from "gatsby"
import { makeStyles } from '@material-ui/core/styles';
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Image from "../components/image"

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "10vw",
    textAlign: "center",
    //height: "80vh"
  },
  phrase: {
    color: "gray",
    textAlign: 'center',
    paddingTop: 150,
    marginLeft: 30,
    marginBottom: 50,
    marginRight: 30,
  },
}));

const ThanksPage = () => {

  const classes = useStyles();
  const breakpoints = useBreakpoint();
  return (
  <Layout breakpoints={breakpoints}>
    <Seo title="お問合せ完了" />
    <div  style={{position: "fixed", top: 0, minWidth: "100%", height: "40vh", zIndex: -1,backgroundColor: "gainsboro"}}><Image filename="overview_background.png" style={{height: "inherit"}}/></div>
    <div className={classes.phrase} style = {{color: "white",fontSize: breakpoints?.sm ? 28 : breakpoints.md ? 36 : 42}}>
      お問合せありがとうございました。
    </div>
    <div style={{backgroundColor: "white", paddingTop: 50,textAlign: "center"}}>
      <p>数日お待ちいただいても連絡がない場合は、当社からのご返信メールが正常に届いていない可能性がございます。</p>
      <p>大変お手数ですが、<Link to="/contact#contact_tel" >お電話</Link>でお知らせいただけますようお願いいたします。</p>
    </div>
  </Layout>
  )
}

export default ThanksPage
